/* global $ blueimp */

$(function () {
  $('#links').click(function (event) {
    event = event || window.event
    var target = event.target || event.srcElement
    var link = target.src ? target.parentNode : target
    var options = {
      index: link,
      event: event
    }
    var links = $(this).find('a')
    blueimp.Gallery(links, options)
  })
})
